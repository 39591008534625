
import TrakkenButton from '~/components/ui/TrakkenButton.vue'
export default {
  data() {
    return {
      loadVideo: false
    }
  },
  mounted() {
    this.loadVideo = true
  },
  props: {
    logo: {
      type: String,
      required: false,
      default: null
    },
    background: {
      type: String,
      required: false,
      default: ''
    },
    promoText: {
      type: String,
      required: false,
      default: null
    },
    isWide: {
      type: Boolean,
      required: false,
      default: false
    },
    mainHeader: {
      type: String,
      required: false,
      default: null
    },
    buttonURL: {
      type: String,
      required: false,
      default: null
    },
    buttonisAnchor: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: false,
      default: null
    },
    scrollTo: {
      type: String,
      required: false,
      default: null
    }
  },
  components: {
    TrakkenButton
  }
}
