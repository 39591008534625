
// import VideoHero from '~/components/VideoHero.vue'
// import HomepageOverviewIntro from '~/components/homepage/HomepageOverviewIntro.vue'
// import HomepageOverviewPlatforms from '~/components/homepage/HomepageOverviewPlatforms.vue'
// import QuotesSlider from '~/components/QuotesSlider.vue'
// import Benefits from '~/components/Benefits.vue'
// import HomepageSolutions from '~/components/homepage/HomepageSolutions.vue'
// import ContactUs from '~/components/ContactUs.vue'
// import SocialsNewsletter from '~/components/SocialsNewsletter.vue'
//import speedkitHydrate from 'nuxt-speedkit/hydrate'
// import LogoWallSlider from '~/components/LogoWallSlider.vue'
// import CaseStudies from '~/components/company/CaseStudies.vue'
// import VerticalSolutions from '~/components/homepage/VerticalSolutions.vue'
export default {
  head() {
    let headerContent = { script: [] }
    if (this.meta_fields) {
      this.setMetaData(this.meta_fields)
      headerContent.title = this.MixinMageTitle
      headerContent.meta = this.MixinMetaData
    }
    return headerContent
  },
  asyncData({ store, app, error }) {
    // Load the JSON from the API
    return app.$storyapi
      .get(`cdn/stories/${store.state.language}/`, {
        version: store.state.version,
        resolve_relations: 'component_related_cases_slide.cases'
      })
      .then((res) => {
        return {
          header: res.data.story.content.header
            ? res.data.story.content.header
            : null,
          overviewDescription: res.data.story.content.overview
            ? res.data.story.content.overview
            : null,
          overview_detail: res.data.story.content.overview_detail
            ? res.data.story.content.overview_detail
            : null,
          quotes: res.data.story.content.quote
            ? res.data.story.content.quote
            : null,
          related_cases:
            res.data.story.content.related_cases !== undefined &&
            res.data.story.content.related_cases[0] !== undefined &&
            res.data.story.content.related_cases[0].related_cases !==
              undefined &&
            res.data.story.content.related_cases[0].related_cases[0] !==
              undefined &&
            res.data.story.content.related_cases[0].related_cases[0].cases !==
              undefined
              ? res.data.story.content.related_cases[0].related_cases[0].cases
              : null,
          related_cases_text:
            res.data.story.content.related_cases !== undefined &&
            res.data.story.content.related_cases[0] !== undefined &&
            res.data.story.content.related_cases[0].text !== undefined
              ? res.data.story.content.related_cases[0].text
              : null,
          services:
            res.data.story.content !== undefined &&
            res.data.story.content.services !== undefined
              ? res.data.story.content.services
              : null,
          tools:
            res.data.story.content !== undefined &&
            res.data.story.content.tools !== undefined
              ? res.data.story.content.tools
              : null,
          color_primary:
            res.data.story.content !== undefined &&
            res.data.story.content.color_primary !== undefined
              ? res.data.story.content.color_primary
              : null,
          color_secondary:
            res.data.story.content !== undefined &&
            res.data.story.content.color_secondary !== undefined
              ? res.data.story.content.color_secondary
              : null,
          color_waves:
            res.data.story.content !== undefined &&
            res.data.story.content.color_waves !== undefined
              ? res.data.story.content.color_waves
              : null,
          meta_fields:
            res.data.story.content.meta_fields !== undefined
              ? res.data.story.content.meta_fields
              : null,
          announcment:
            res.data.story.content.announcement[0] !== undefined &&
            res.data.story.content.announcement[0] !== ''
              ? res.data.story.content.announcement[0]
              : null,
          social_newsletter:
            res.data.story.content.social_newsletter !== undefined &&
            res.data.story.content.social_newsletter !== ''
              ? res.data.story.content.social_newsletter
              : null,
          logo_wall:
            res.data.story.content.partner_logos !== undefined &&
            res.data.story.content.partner_logos !== ''
              ? res.data.story.content.partner_logos
              : null,
          vertical_solutions:
            res.data.story.content.block[0].all_vertical_solutions !==
              undefined &&
            res.data.story.content.block[0].all_vertical_solutions !== ''
              ? res.data.story.content.block[0].all_vertical_solutions
              : null
        }
      })
      .catch((res) => {
        if (!res.response) {
          error({
            statusCode: 404,
            message: 'Failed to receive content from Storyblok API'
          })
        } else {
          error({
            statusCode: res.response.status,
            message: res.response.data
          })
        }
      })
  },
  data() {
    return {
      ra: null,
      story: { content: {} },
      wakeLazy: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 0]
      }
    }
  },
  mounted() {
    // use the bridge to listen to events
    this.$storybridge.on(['input', 'published', 'change'], (event) => {
      if (event.action == 'input') {
        if (event.story.id === this.story.id) {
          this.story.content = event.story.content
        }
      } else {
        this.$nuxt.$router.go({
          path: this.$nuxt.$router.currentRoute,
          force: true
        })
      }
    })
    //console.log('adding listener')
    /* window.addEventListener('DOMContentLoaded', () => {
      console.log('is loaded now')
    }) */
  },
  methods: {
    wakeUpLazy({ going }) {
      if (going === this.$waypointMap.GOING_IN && !this.wakeLazy) {
        this.wakeLazy = true
      }
    }
  },
  computed: {
    benefits() {
      if (!this.overview_detail || this.overview_detail == undefined) {
        return null
      }
      return [
        {
          benefits:
            this.overview_detail &&
            this.overview_detail !== undefined &&
            this.overview_detail[0] !== undefined &&
            this.overview_detail[0].tile !== undefined &&
            this.overview_detail[0].tile !== ''
              ? this.overview_detail[0].tile
              : null,
          textline: [
            {
              text:
                this.overview_detail &&
                this.overview_detail !== undefined &&
                this.overview_detail[0] !== undefined &&
                this.overview_detail[0].text !== undefined &&
                this.overview_detail[0].text[0] !== undefined &&
                this.overview_detail[0].text[0].textline !== undefined &&
                this.overview_detail[0].text[0].textline[0] !== undefined &&
                this.overview_detail[0].text[0].textline[0].text !==
                  undefined &&
                this.overview_detail[0].text[0].textline[0].text !== ''
                  ? this.overview_detail[0].text[0].textline[0].text
                  : null
            }
          ],
          textline_textblock: [
            {
              textline: [
                {
                  text:
                    this.overview_detail &&
                    this.overview_detail !== undefined &&
                    this.overview_detail[0] !== undefined &&
                    this.overview_detail[0].text !== undefined &&
                    this.overview_detail[0].text[0] !== undefined &&
                    this.overview_detail[0].text[0].textline_2 !== undefined &&
                    this.overview_detail[0].text[0].textline_2[0] !==
                      undefined &&
                    this.overview_detail[0].text[0].textline_2[0].text !==
                      undefined
                      ? this.overview_detail[0].text[0].textline_2[0].text
                      : null
                }
              ],
              textblock: [
                {
                  text:
                    this.overview_detail &&
                    this.overview_detail !== undefined &&
                    this.overview_detail[0] !== undefined &&
                    this.overview_detail[0].text !== undefined &&
                    this.overview_detail[0].text[0] !== undefined &&
                    this.overview_detail[0].text[0].textblock !== undefined &&
                    this.overview_detail[0].text[0].textblock[0] !==
                      undefined &&
                    this.overview_detail[0].text[0].textblock[0].text !==
                      undefined &&
                    this.overview_detail[0].text[0].textblock[0].text !== ''
                      ? this.overview_detail[0].text[0].textblock[0].text
                      : null
                }
              ]
            }
          ]
        }
      ]
    },
    pageVar() {
      let pageVars = {
        name:
          this.header !== undefined &&
          this.header[0] !== undefined &&
          this.header[0].textline !== undefined &&
          this.header[0].textline[0] !== undefined &&
          this.header[0].textline[0].text !== undefined &&
          this.header[0].textline[0].text !== ''
            ? this.header[0].textline[0].text
            : null,
        // primaryColor: '#F9AB00',
        secondaryColor:
          this.color_secondary !== undefined &&
          this.color_secondary[0] !== undefined &&
          this.color_secondary[0].color !== undefined
            ? this.color_secondary[0].color
            : '#F9AB00',
        // secondaryColor: '#E37400',
        primaryColor:
          this.color_primary !== undefined &&
          this.color_primary[0] !== undefined &&
          this.color_primary[0].color !== undefined
            ? this.color_primary[0].color
            : '#E37400',
        // wavesColor: '#EEEEEE'
        wavesColor:
          this.color_waves !== undefined &&
          this.color_waves[0] !== undefined &&
          this.color_waves[0].color !== undefined
            ? this.color_waves[0].color
            : '#EEEEEE'
      }
      return pageVars
    }
  }
  /* components: {
    VideoHero,
    HomepageOverviewIntro,
    HomepageOverviewPlatforms,
    QuotesSlider,
    Benefits,
    SocialsNewsletter,
    HomepageSolutions,
    ContactUs,
    CaseStudies,
    VerticalSolutions,
    LogoWallSlider
  } */
  //dynamic import
  /* components: {
    VideoHero: speedkitHydrate(() => import('~/components/VideoHero.vue')),
    HomepageOverviewIntro: speedkitHydrate(() =>
      import('~/components/homepage/HomepageOverviewIntro.vue')
    ),
    HomepageOverviewPlatforms: speedkitHydrate(() =>
      import('~/components/homepage/HomepageOverviewPlatforms.vue')
    ),
    QuotesSlider: speedkitHydrate(() =>
      import('~/components/QuotesSlider.vue')
    ),
    Benefits: speedkitHydrate(() => import('~/components/Benefits.vue')),
    SocialsNewsletter: speedkitHydrate(() =>
      import('~/components/SocialsNewsletter.vue')
    ),
    HomepageSolutions: speedkitHydrate(() =>
      import('~/components/homepage/HomepageSolutions.vue')
    ),
    ContactUs: speedkitHydrate(() => import('~/components/ContactUs.vue')),
    VerticalSolutions: speedkitHydrate(() =>
      import('~/components/homepage/VerticalSolutions.vue')
    ),
    LogoWallSlider: speedkitHydrate(() =>
      import('~/components/LogoWallSlider.vue')
    ),
    CaseStudies: speedkitHydrate(() =>
      import('~/components/company/CaseStudies.vue')
    )
  } */
}
////Prevent Google Product from load Fonts

////
