
import TrakkenButton from '~/components/ui/TrakkenButton.vue'
export default {
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: '#fff'
    },
    textColor: {
      type: String,
      required: false,
      default: 'inherit'
    },
    announcment: {
      type: Object,
      required: true
    }
  },
  components: {
    TrakkenButton
  },
  data() {
    return {
      story: { content: {} }
    }
  },
  methods: {
    openPdf() {
      const url = this.getFileUrl(this.announcment.file_to_download.filename)
      window.open(url, '_blank')
    },
    getImageUrl(original) {
      if (original.includes('svg')) {
        return original.replace(
          'https://a.storyblok.com',
          'https://a.trkkn.com'
        )
      } else {
        return `${original.replace(
          'https://a.storyblok.com',
          'https://a.trkkn.com'
        )}/m/`
      }
    },
    getFileUrl(original) {
      return original.replace('https://a.storyblok.com', 'https://a.trkkn.com')
    }
  },
  beforeMount() {
    this.$store.dispatch('loadContact', 'published')
  },
  computed: {
    buttonClass() {
      return this.textColor == 'inherit' ? 'btn-outline-primary' : 'btn-light'
    },
    contactUsStyle() {
      return {
        '--background-color': '#DB0000',
        '--color': '#FFFFFF'
      }
    },
    contactDetails() {
      return this.$store.state.contact
    }
  }
}
