
export default {
  props: {
    prehead: {
      type: String,
      required: true
    },
    primaryColor: {
      type: String,
      required: false
    },
    secondaryColor: {
      type: String,
      required: false
    },
    reverseColors: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    getColor() {
      if (!this.reverseColors) return this.primaryColor
      return this.secondaryColor
    }
  }
}
