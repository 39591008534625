
import VueMarkdown from 'vue-markdown'
import TrakkenButton from '~/components/ui/TrakkenButton.vue'
import PreHead from '~/components/ui/PreHead.vue'
export default {
  props: {
    richText: {
      type: String,
      required: false
    },
    preHeader: {
      type: String,
      required: false
    },
    mainHeader: {
      type: String,
      required: false
    },
    mainText: {
      type: String,
      required: false
    },
    iconBadges: {
      type: Array,
      required: false
    },
    linkText: {
      type: String,
      required: false
    },
    linkURL: {
      type: String,
      required: false
    },
    isExternalLink: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonStyle: {
      type: String,
      required: false
    },
    highlightColor: {
      type: String,
      required: false,
      default: '#74797f'
    },
    isGCP: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    TrakkenButton,
    VueMarkdown,
    PreHead
  },
  methods: {
    getColor() {
      if (this.highlightColor) return this.highlightColor
      return '#74797f'
    }
  }
}
